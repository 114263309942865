<template>
  <div id="content" class="main-content">
    <div class="m-4">
      <b-row class="layout-spacing layout-top-spacing" id="cancel-row">
        <div class="col-xl-12 col-sm-12 layout-spacing">
          <div class="statbox widget box box-shadow">
            <div class="widget-header">
              <b-row>
                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                  <h4>{{ $t("newBookings.title") }}</h4>
                </div>
              </b-row>
            </div>
            <div class="widget-content widget-content-area">
              <b-form>
                <b-form-row>
                  <b-col class="col-md-8">
                    <b-button
                      class="btn btn-outline-primary mb-6 mr-2"
                      @mouseover="isHovering = true"
                      @mouseout="isHovering = false"
                      @click="cancelNewBooking()"
                    >

                      <b-img
                        class="feathericons-arrow-left p-2"
                        :class="{
                          'bg-white': isHovering,
                          'bg-primary': !isHovering
                        }"
                      />
                      {{ $t("newBookings.back") }}
                    </b-button>
                  </b-col>
                  <b-col class="col-md-4">
                    <b-form-group
                      v-if="chosenCompanion"
                      class="text-center mt-n6"
                    >
                      <div class="avatar avatar-xl">
                        <b-img
                          alt="avatar"
                          :src="chosenCompanion.profile.picture"
                          class="rounded-circle max-width-profile-picture"
                        />
                      </div>
                      <p class="mt-3">
                        {{ $t("newBookings.yourCompanion") }}
                      </p>
                      <h6>
                        {{ chosenCompanion.profile.name }}
                      </h6>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <div v-if="loading">
                  <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                    <div
                      class="spinner-border text-success align-self-center loader-lg"
                    />
                  </div>
                </div>
                <div v-else-if="loadingError" class="cm-loading-error">
                  {{ $t("calendarModule.errors.loadingError") }}
                </div>
                <div v-else>
                  <div v-if="isEditAppointment">
                    <h6 class="color-warning text-center font-weight-bold">
                      {{ $t("bookingSelection.isEditAppointment") }}
                    </h6>
                  </div>
                  <b-col cols="12">
                    <div class="calendar-wrapper-client">
                      <div class="d-flex">
                        <div class="flex-fill text-left">
                          <b-icon-arrow-left
                            @click="changeMonth('pastMonth')"
                            font-scale="1.5"
                            style="cursor: pointer"
                          />
                        </div>
                        <div class="flex-fill text-center">
                          {{ start.format("MMMM .YY") }}
                        </div>
                        <div class="flex-fill text-right">
                          <b-icon-arrow-right
                            @click="changeMonth('futureMonth')"
                            font-scale="1.5"
                            style="cursor: pointer"
                          />
                        </div>
                      </div>
                      <table
                        class="calendar-table"
                        id="calendarBody"
                        v-if="
                          selectedCalendar !== null && selectedCalendar !== ''
                        "
                      >
                        <tr style="font-weight: 700">
                          <td class="day">Mo</td>
                          <td class="day">Di</td>
                          <td class="day">Mi</td>
                          <td class="day">Do</td>
                          <td class="day">Fr</td>
                          <td class="day">Sa</td>
                          <td class="day">So</td>
                        </tr>
                        <tr v-for="(row, index) in calendarDays" :key="index">
                          <td
                            :class="{ notCalendarDay: !day.isCalendarDay }"
                            v-for="day in row"
                            :key="day.dayOfMonth"
                            @click="openSlots(day.slot), setGlobalDay(day)"
                          >
                            <div
                              class="m-0 w-100 border-radius-75-pixel day pt-1"
                              :class="
                                isSlotCircle(day.slot)
                                  ? 'notSlotCircle'
                                  : isSlotCircleClicked(day)
                                  ? 'slotCircleClicked'
                                  : 'slotCircle'
                              "
                            >
                              {{ day.dayOfMonth }}
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="mt-5 text-center" v-if="isSlotEmpty">
                      <h5 class="font-weight-bold font-size-middle">
                        {{ $t("bookingSelection.slotIsEmpty") }}
                      </h5>
                    </div>
                    <div v-if="selectedSlots.length > 0 && !isSlotEmpty">
                      <div class="d-flex flex-row mt-5">
                        <img class="feathericons-clock p-3 mr-1 bg-success" />
                        <div class="pl-1">
                          <div>
                            {{ `${$t("bookingSelection.freeSlotsFor")} `
                            }}<strong>{{
                              moment(
                                selectedSlots[0].date,
                                "YYYY-MM-DD"
                              ).format("DD.MM.YYYY")
                            }}</strong>
                          </div>
                          <div>{{ $t("bookingSelection.selectTime") }}</div>
                        </div>
                      </div>
                      <div>
                        <b-row class="pt-3">
                          <b-col
                            class="col-12 col-md-6 col-lg-3"
                            v-for="slot in mergedSlots"
                            :key="slot._id"
                            @click="selectSlot(slot)"
                          >
                            <CalendarSlotTile
                              :slotObject="slot"
                              :is-selected="isSlotSelected(slot)"
                              :can-be-clicked="true"
                            />
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                    <!-- Edit Calendar Modal -->
                    <b-modal
                      ref="submitBookingModal"
                      size="lg"
                      :title="$t('bookingSelection.submitBookingModalTitle')"
                      hide-footer
                    >
                      <b-row>
                        <b-col>
                          <p>
                            {{
                              $t(
                                "bookingSelection.submitBookingModalDescription"
                              )
                            }}
                          </p>
                        </b-col>
                      </b-row>
                      <b-row class="mt-5">
                        <b-col>
                          <p>{{ $t("newBookings.yourCompanion") }}:</p>
                          <ul class="list-group list-group-media">
                            <li class="list-group-item list-group-item-action">
                              <div class="media">
                                <div class="mr-3">
                                  <img
                                    alt="avatar"
                                    :src="chosenCompanion.profile.picture"
                                    class="img-fluid rounded-circle max-width-profile-picture-small"
                                  />
                                </div>
                                <div class="media-body">
                                  <h6 class="tx-inverse">
                                    {{ chosenCompanion.profile.name }}
                                  </h6>
                                  <p
                                    v-if="
                                      chosenCompanion.role ===
                                        EUserRoles.COMPANION
                                    "
                                    class="mg-b-0"
                                  >
                                    {{ $t("kingOfTheCastle.companions") }}
                                  </p>
                                  <p
                                    v-else-if="
                                      chosenCompanion.role ===
                                        EUserRoles.STUDENT
                                    "
                                    class="mg-b-0"
                                  >
                                    {{ $t("kingOfTheCastle.student") }}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </b-col>
                        <b-col>
                          <div v-if="selectedSlot" class="pl-2">
                            <p>
                              {{ `${$t("bookingSelection.yourChosenDate")} ` }}
                            </p>
                            <strong>{{
                              `${moment(selectedSlot.date, "YYYY-MM-DD").format(
                                "DD.MM.YYYY"
                              )}  ${$t("misc.at")}  ${selectedSlot.time}`
                            }}</strong>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row v-if="!isEditAppointment" class="mt-5">
                        <b-col>
                          <label>{{ $t("bookingSelection.myComment") }}</label>
                          <b-img
                            id="tooltip-reason"
                            class="feathericons-help-circle p-2 ml-1 bg-success"
                          />
                          <b-tooltip target="tooltip-reason" triggers="hover">
                            <span>{{
                              $t("bookingSelection.toolTips.comment")
                            }}</span>
                          </b-tooltip>
                          <b-textarea
                            v-model="$v.submitBookingComment.$model"
                            class="form-control border-text-area-characters"
                            rows="3"
                          ></b-textarea>
                          <p class="text-center border-soul-letters-characters">
                            ({{ commentCharacterCount }} / 500)
                            {{ $t("soulLetter.characters") }}
                          </p>
                          <span
                            class="inputRequired"
                            v-if="!$v.submitBookingComment.required"
                          >
                            {{ $t("bookingSelection.error.fieldRequired") }}
                          </span>
                          <span
                            class="error"
                            v-if="!$v.submitBookingComment.maxLength"
                          >
                            {{ $t("bookingSelection.error.maxComment") }}
                          </span>
                        </b-col>
                      </b-row>
                      <b-row class="mt-3">
                        <b-col v-if="!isEditAppointment" class="col-6">
                          <label>{{ $t("bookingSelection.voucher") }}</label>
                          <b-input
                            v-model="$v.voucher.$model"
                            type="text"
                            class="form-control"
                          />
                        </b-col>
                        <b-col
                          v-if="!isEditAppointment"
                          class="d-flex flex-column justify-flex-end col-3"
                        >
                          <button
                            @click.prevent="checkVoucher()"
                            class="btn btn-outline-warning"
                            :disabled="!$v.voucher.required"
                          >
                            {{ $t("bookingSelection.checkVoucher") }}
                          </button>
                        </b-col>
                      </b-row>
                      <transition name="fade">
                        <b-row v-if="!isEditAppointment && isVoucherError">
                          <b-col class="col-6">
                            <div class="mt-2 mb-2">
                              <div
                                class="alert alert-light-danger border-0 mb-4"
                                role="alert"
                              >
                                <button
                                  @click.prevent="isVoucherError = false"
                                  type="button"
                                  class="close"
                                  data-dismiss="alert"
                                  aria-label="Close"
                                >
                                  <img
                                    class="feathericons-x bg-danger p-3 mt-n1 ml-1"
                                  />
                                </button>
                                {{
                                  $t(
                                    "bookingSelection.error.status.invalidVoucherDescription"
                                  )
                                }}
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row
                          v-else-if="!isEditAppointment && isVoucherSuccess"
                        >
                          <b-col class="col-6">
                            <div class="mt-2 mb-2">
                              <div
                                class="alert alert-light-success border-0 mb-4"
                                role="alert"
                              >
                                <button
                                  @click.prevent="isVoucherSuccess = false"
                                  type="button"
                                  class="close"
                                  data-dismiss="alert"
                                  aria-label="Close"
                                >
                                  <img
                                    class="feathericons-x bg-dark p-3 mt-n1 ml-1"
                                  />
                                </button>
                                {{
                                  $t(
                                    "bookingSelection.error.status.validVoucherDescription"
                                  )
                                }}
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </transition>
                      <b-row class="mt-5">
                        <b-col>
                          <h6>{{ $t("bookingSelection.europeanTime") }}</h6>
                        </b-col>
                      </b-row>
                      <b-row v-if="chosenCompanion" class="mt-2">
                        <b-col
                          v-if="chosenCompanion.profile.name === 'Clemens Kuby'"
                        >
                          <h6>
                            {{ $t("bookingSelection.pricesClemensKuby") }}
                          </h6>
                        </b-col>
                        <b-col
                          v-if="chosenCompanion.profile.name !== 'Clemens Kuby'"
                        >
                          <h6>{{ $t("bookingSelection.prices") }}</h6>
                        </b-col>
                      </b-row>
                      <b-row class="mt-5">
                        <b-col>
                          <b-form-checkbox
                            id="allowRecordConversation-Checkbox"
                            v-model="allowRecordConversation"
                          >
                            <span>{{
                              $t("bookingSelection.recordConversation")
                            }}</span>
                            <b-img
                              id="tooltip-allow-record-conversation"
                              class="feathericons-help-circle p-2 ml-1 bg-success"
                            />
                            <b-tooltip
                              target="tooltip-allow-record-conversation"
                              triggers="hover"
                            >
                              <p class="color-white">
                                {{
                                  $t(
                                    "bookingSelection.toolTips.allowRecordAudio"
                                  )
                                }}
                              </p>
                              <p class="color-white">
                                {{
                                  $t(
                                    "bookingSelection.toolTips.allowRecordAudio1"
                                  )
                                }}
                              </p>
                              <p class="color-white">
                                {{
                                  $t(
                                    "bookingSelection.toolTips.allowRecordAudio2"
                                  )
                                }}
                              </p>
                              <p class="color-white">
                                {{
                                  $t(
                                    "bookingSelection.toolTips.allowRecordAudio3"
                                  )
                                }}
                              </p>
                            </b-tooltip>
                          </b-form-checkbox>
                          <b-form-checkbox
                            id="allowPrivacyPolicy-Checkbox"
                            v-model="allowPrivacyPolicy"
                          >
                            {{ $t("bookingSelection.privacyPolicy1") }}
                            <a
                              href="https://clemenskuby.com/home/agbs/"
                              target="_blank"
                            >
                              {{ $t("bookingSelection.agb") }}</a
                            >
                            {{ $t("bookingSelection.privacyPolicy2") }}
                            <a
                              href="https://clemenskuby.com/datenschutz/"
                              target="_blank"
                              >{{ $t("bookingSelection.dataPrivacy") }}</a
                            >
                            {{ $t("bookingSelection.privacyPolicy3") }}
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                      <b-row class="mt-5">
                        <b-col>
                          <button
                            v-if="!isEditAppointment"
                            @click="submitBooking"
                            :disabled="
                              !allowPrivacyPolicy ||
                                !allowRecordConversation ||
                                $v.submitBookingComment.$invalid ||
                                isBookingLoading
                            "
                            class="btn btn-primary"
                          >
                            {{ $t("misc.bookAppointment") }}
                          </button>
                          <button
                            v-else-if="isEditAppointment"
                            @click="submitBookingEditAppointment"
                            :disabled="
                              !allowPrivacyPolicy || !allowRecordConversation
                            "
                            class="btn btn-primary"
                          >
                            {{ $t("misc.moveAppointment") }}
                          </button>
                          <button
                            @click="closeSubmitBookingModal"
                            class="btn btn-outline-primary ml-2"
                          >
                            {{ $t("misc.abort") }}
                          </button>
                        </b-col>
                      </b-row>
                    </b-modal>
                  </b-col>
                </div>
                <notifications classes="top-right-notification" />
              </b-form>
            </div>
          </div>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/de";
import * as cmApi from "../../store/modules/calendarModule/calendarModule.api";
import * as conversationApi from "../../store/modules/conversations/conversations.api";
import * as voucherApi from "../../store/modules/voucher/voucher.api";
import * as userApi from "@/store/modules/users/users.api";
import * as moment from "moment";
import { addNotification } from "../../utils/notificationHandler";
import { mapGetters } from "vuex";
import { helpers, maxLength, required } from "vuelidate/lib/validators";
import { EUserRoles } from "../../store/modules/users/EUserRoles";
import {convertToLocal, convertToLocalMoment} from "../../utils/timezoneHelper";
import {EConversationTypes} from "../../store/modules/conversations/EConversationTypes";
const telephoneNumber = helpers.regex("phoneNumber", /^[0-9+]*$/);

moment.locale("de");

export default {
  name: "BookingSelection2",
  title: "Buchungsauswahl",
  components: {
    CalendarSlotTile: () =>
      import("../../components/calendarModule/common/CalendarSlotTile")
  },
  props: {
    isChangeFromCompanion: { type: Boolean, default: false },
    isEditAppointment: { type: Boolean, default: false },
    editAppointmentChosenConversation: Object,
    chosenCompanion: Object,
    selectedCalendar: Object,
    changedSelection: { type: Function, required: false },
    minDate: { type: Object, default: null },
    maxDate: { type: Object, default: null },
    onlyExcluded: { type: Boolean, default: false },
    isExtraCapacity: { type: Boolean, default: false },
    slotFiltersTypes: { type: Array }
  },
  data() {
    return {
      isHovering: false,
      isVoucherError: false,
      isVoucherSuccess: false,
      isSlotEmpty: false,
      chosenDates: [],
      EUserRoles,
      allowRecordConversation: false,
      allowPrivacyPolicy: false,
      voucher: null,
      phoneNumber: null,
      charactersCountReason: 0,
      submitBookingComment: "",
      submitBookingModalOpen: false,
      currentUser: null,
      moment: moment,
      tmpSlots: [],
      slots: [],
      start: moment().startOf("month"),
      end: moment().endOf("month"),
      daySelected: false,
      selectedSlots: [],
      selectedSlot: null,
      chosenDayGlobal: -1,
      loading: false,
      loadingError: false,
      transaction: null,
      isBookingLoading: false
    };
  },
  validations: {
    submitBookingComment: {
      maxLength: maxLength(500),
      required
    },
    phoneNumber: {
      telephoneNumber,
      telNrMaxLength: maxLength(15)
    },
    voucher: {
      required
    }
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"]),
    commentCharacterCount() {
      return this.submitBookingComment.length;
    },
    mergedSlots() {
      return this.selectedSlots.reduce((acc, current) => {
        const x = acc.find(
          item =>
            item._id !== current._id &&
            item.time === current.time &&
            item.endTime === current.endTime &&
            item.date === current.date &&
            item.endDate === current.endDate
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    },
    calendarDays: function() {
      const startCpy = this.start.clone();
      const rows = [];
      let days = [];

      let date = startCpy.clone().add(-(startCpy.clone().day() - 1), "day");
      if (this.start.day() === 0) {
        date = startCpy.clone().add(-6, "day");
      }

      const today = moment();
      while (date.isBefore(this.start)) {
        days.push({
          dayOfMonth: date.date(),
          isCalendarDay: true,
          isToday:
            date.date() === today.date() && date.month() === today.month(),
          slot: this.slots.filter(
            slot => slot.date === date.format("YYYY-MM-DD")
          ),
          isChosenDate: false
        });
        date.add(1, "day");
      }

      while (startCpy.isBefore(this.end)) {
        days.push({
          dayOfMonth: startCpy.date(),
          isCalendarDay: true,
          isToday:
            startCpy.date() === today.date() &&
            startCpy.month() === today.month(),
          slot: this.slots.filter(
            slot => slot.date === startCpy.format("YYYY-MM-DD")
          ),
          isChosenDate: false
        });

        if (startCpy.day() === 0) {
          rows.push(days);
          days = [];
        }
        startCpy.add(1, "day");
      }

      if (startCpy.day() !== 1 && startCpy.day() !== 0) {
        const countDays = 7 - startCpy.clone().day() + 1;
        for (let i = 0; i < countDays; i++) {
          days.push({
            dayOfMonth: startCpy.date(),
            isCalendarDay: true,
            isToday:
              startCpy.date() === today.date() &&
              startCpy.month() === today.month(),
            slot: this.slots.filter(
              slot => slot.date === startCpy.format("YYYY-MM-DD")
            ),
            isChosenDate: false
          });
          startCpy.add(1, "day");
        }
      } else if (startCpy.day() === 0) {
        days.push({
          dayOfMonth: startCpy.date(),
          isCalendarDay: true,
          isToday:
            startCpy.date() === today.date() &&
            startCpy.month() === today.month(),
          slot: [],
          isChosenDate: false
        });
      }

      rows.push(days);
      return rows;
    }
  },
  methods: {
    cancelNewBooking() {
      this.$router.push("/dashboard");
    },
    conversationSaved() {
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
    },
    conversationSavedSuccess() {
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
      addNotification(
        "success",
        this.$t("misc.success"),
        this.$t("bookingSelection.success.createConversationSuccess")
      );
    },
    conversationSavedError() {
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
      addNotification(
        "error",
        this.$t("misc.error"),
        this.$t("bookingSelection.error.createConversationError")
      );
    },
    isSlotCircleClicked(day) {
      if (
        day.isCalendarDay &&
        day.dayOfMonth === this.chosenDayGlobal.dayOfMonth &&
        day.slot.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    isSlotCircle(day) {
      let isSlotEmpty = true;
      const slots = day;
      if (slots) {
        const filteredSlots = this.filterForAvailableSlots(slots);
        isSlotEmpty = filteredSlots.length === 0;
      }
      return isSlotEmpty;
    },
    checkForSlotCircle(slots) {
      const isSlotCircle = this.isSlotCircle(slots);
      return isSlotCircle;
    },
    isChosenDateClicked(row, day, idx) {
      const dayIdx = parseInt(idx, 10);
    },
    updateAppointment() {
      if (this.editAppointmentChosenConversation) {
        conversationApi
          .updateConversation(this.editAppointmentChosenConversation._id, {
            appointment: this.selectedSlot._id,
            host: this.editAppointmentChosenConversation.host._id,
            subject: this.editAppointmentChosenConversation.subject
          })
          .then(res => {
            this.loading = false;
            this.loadingError = false;
            this.$emit("conversationSavedSuccess");
          })
          .catch(err => {
            this.loading = false;
            this.loadingError = true;
            this.$emit("conversationSavedError");
          });
      }
    },
    openSubmitBookingModal() {
      this.submitBookingModalOpen = true;
      this.$refs["submitBookingModal"].show();
    },
    closeSubmitBookingModal() {
      this.submitBookingModalOpen = false;
      this.$refs["submitBookingModal"].hide();
    },
    loadSlots(start, end) {
      this.slots = [];
      let fromTime = "00:00";

      if (this.minDate && this.moment(this.minDate).isAfter(start)) {
        start = this.minDate;
      }
      if (this.maxDate && this.moment(this.maxDate).isBefore(end)) {
        end = this.maxDate;
      }

      if (moment().isAfter(start)) {
        start = moment();
        fromTime = moment().format("HH:mm");
      }

      this.loading = true;
      this.loadingError = false;
      cmApi.slot
        .getAllTimeSlots(this.chosenCompanion._id)
        .then(res => {
          const timeSlots = res.data.data;
          timeSlots.forEach(slot => {
            if (slot.free) {
              const convertedUtc = convertToLocal(
                slot.date,
                slot.endDate,
                slot.time,
                slot.endTime
              );
              const formattedSlot = {
                date: convertedUtc.date,
                endDate: convertedUtc.endDate,
                time: convertedUtc.time,
                endTime: convertedUtc.endTime,
                free: slot.free,
                _id: slot._id
              };
              this.slots.push(formattedSlot);
            }
          });
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    selectSlot(slot) {
      this.selectedSlot = slot;
      this.openSubmitBookingModal();
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth"
        });
      }, 50);
    },
    filterForAvailableSlots(slots) {
      const filteredSlots = [];
      const now = moment();
      let slotMinBookingHours;
      if (this.chosenCompanion.companionStudentSettings.slotMinBookingHours) {
        slotMinBookingHours = this.chosenCompanion.companionStudentSettings
            .slotMinBookingHours;
      } else {
        slotMinBookingHours = 6;
      }

      for (const slot of slots) {
        const slotDate = convertToLocalMoment(slot.date, slot.time);
        if (slotDate < now || now > slotDate.subtract(slotMinBookingHours, "hours")) continue;
        filteredSlots.push(slot);
      }
      return filteredSlots;
    },
    setGlobalDay(day) {
      this.chosenDayGlobal = day;
    },
    openSlots(slots) {
      this.selectedSlot = null;
      if (slots.length > 0) {
        const toDay = moment().format("YYYY-MM-DD");
        if (moment(slots[0].date).diff(moment(toDay)) >= 0) {
          this.selectedSlots = this.filterForAvailableSlots(slots);
          this.isSlotEmpty = this.selectedSlots.length === 0;
          this.daySelected = true;
        } else {
          this.daySelected = false;
          this.selectedSlots = [];
          this.$bvToast.toast(this.$t("bookingSelection.noSlotsAvailable"), {
            title: this.$t("misc.attention"),
            variant: "warning",
            solid: true,
            autoHideDelay: 4000,
            appendToast: true,
            toaster: "b-toaster-top-right"
          });
        }
      } else {
        this.daySelected = false;
        this.selectedSlots = [];
        this.$bvToast.toast(this.$t("bookingSelection.noSlotsAvailable"), {
          title: this.$t("misc.attention"),
          variant: "warning",
          solid: true,
          autoHideDelay: 4000,
          appendToast: true,
          toaster: "b-toaster-top-right"
        });
      }
    },
    isSlotSelected(slot) {
      return this.selectedSlot && slot._id === this.selectedSlot._id;
    },
    changeMonth(type) {
      if (type === "pastMonth") {
        this.start.add(-1, "month").startOf("month");
        this.end.add(-1, "month").endOf("month");
      } else {
        this.start.add(1, "month").startOf("month");
        this.end.add(1, "month").endOf("month");
      }
      this.loadSlots(this.start, this.end);
    },
    submitBookingEditAppointment() {
      this.loading = true;
      this.loadingError = false;

      conversationApi
        .updateConversation(this.editAppointmentChosenConversation._id, {
          appointment: this.selectedSlot._id,
          host: this.editAppointmentChosenConversation.host._id,
          subject: this.editAppointmentChosenConversation.subject
        })
        .then(res => {
          this.loading = false;
          this.loadingError = false;
          this.$emit("conversationSavedSuccess");
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
          this.$emit("conversationSavedError");
        });
    },
    checkVoucher() {
      const payload = {
        host: this.chosenCompanion._id,
        voucherCode: this.voucher
      };
      voucherApi
        .checkVoucher(payload)
        .then(res => {
          this.isVoucherSuccess = true;
          this.isVoucherError = false;
        })
        .catch(err => {
          if (err.response) {
            if (err.response.data) {
              if (err.response.data.data.error) {
                if (
                  err.response.data.data.error ===
                  this.$t("bookingSelection.error.status.invalidVoucher")
                ) {
                  this.isVoucherError = true;
                  this.isVoucherSuccess = false;
                }
              }
            }
          }
        });
    },
    submitBooking() {
      this.isBookingLoading = true;
      if (!this.allowRecordConversation || !this.allowPrivacyPolicy) {
        this.isBookingLoading = false;
        return;
      }
      let payloadRandomSlot;
      if (this.submitBookingComment !== "") {
        if (this.voucher) {
          payloadRandomSlot = {
            appointment: this.selectedSlot._id,
            host: this.chosenCompanion._id,
            subject: this.submitBookingComment,
            voucherCode: this.voucher,
            type: EConversationTypes.ONE_ON_ONE
          };
        } else {
          payloadRandomSlot = {
            appointment: this.selectedSlot._id,
            host: this.chosenCompanion._id,
            subject: this.submitBookingComment,
            type: EConversationTypes.ONE_ON_ONE
          };
        }
      } else {
        if (this.voucher) {
          payloadRandomSlot = {
            appointment: this.selectedSlot._id,
            host: this.chosenCompanion._id,
            voucherCode: this.voucher,
            type: EConversationTypes.ONE_ON_ONE
          };
        } else {
          payloadRandomSlot = {
            appointment: this.selectedSlot._id,
            host: this.chosenCompanion._id,
            type: EConversationTypes.ONE_ON_ONE
          };
        }
      }
      conversationApi
        .createConversation(payloadRandomSlot)
        .then(res => {
          const conversation = res.data.data;
          if (conversation.transaction) {
            this.transaction = conversation.transaction;
            window.open(this.transaction.buyUrl.url);
          }
          this.isBookingLoading = false;
          this.submitBookingModalOpen = false;
          this.$emit("conversationSavedSuccess");
        })
        .catch(err => {
          if (err.response) {
            if (err.response.data) {
              if (err.response.data.data.error) {
                if (
                  err.response.data.data.error ===
                  this.$t("bookingSelection.error.status.invalidVoucher")
                ) {
                  this.isVoucherError = true;
                  this.isVoucherSuccess = false;
                } else {
                  this.isVoucherSuccess = false;
                  this.$emit("conversationSavedError");
                }
              }
            }
          }
          this.isBookingLoading = false;
        });
    },
    showError(message) {
      addNotification("warn", this.$t("misc.attention"), message);
    },
    getChosenCompanion(givenUserId) {
      this.loading = true;
      this.loadingError = false;
      userApi
        .getUser(givenUserId)
        .then(res => {
          const user = res.data.data;
          this.chosenCompanion = user;
          this.loadSlots(this.start, this.end);
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    }
  },
  mounted() {
    const givenUserId = this.$route.params.userId;
    if (givenUserId) {
      this.getChosenCompanion(givenUserId);
    } else {
      this.loadingError = true;
    }

    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
  }
};
</script>
